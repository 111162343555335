import React from "react"

class Divider extends React.Component {
    render() {
        return(
            <section className="pt-0 pb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            <hr className="feature-divider"/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Divider


