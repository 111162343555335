import React, {Component} from 'react';

class Header extends Component {
    render() {
        return (
            <header id="navbar-spy" className="header header-1 header-transparent header-fixed">
                <nav id="primary-menu" className="navbar navbar-fixed-top">
                    <div className="container">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse-1" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <a className="logo mt-20" href="/">
                                <img className="logo-dark" src={this.props.logo} alt="Mason logo" width="100" />
                                <img className="logo-light" src={this.props.logo} alt="Mason logo" width="100" />
                            </a>
                        </div>
                        <div className="collapse navbar-collapse pull-right" id="navbar-collapse-1">
                            <ul className="nav navbar-nav nav-pos-right navbar-left nav-split">
                                <li><a data-scroll="scrollTo" href="#slider">home</a></li>
                                <li><a data-scroll="scrollTo" href="#feature1">features</a></li>
                                <li><a data-scroll="scrollTo" href="#newsletter2">register</a></li>
                                <li><a href={'https://docs.iconmason.com/#/'}>User's Guide</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Header
