const Utils = {
    processTokens : (text) => {
        const kLINKS = {
            IconJar          : "https://a.paddle.com/v2/click/17524/112343?link=2979",
            RoundIcons       : "https://a.paddle.com/v2/click/36599/112343?link=2978",
            DiversityAvatars : "https://diversityavatars.com"
        }

        console.log('Process text', text);

        const getLink = (url, text) => {
            return `<a href="${url}" target="_blank">${text}</a>`;
        }

        text = text.replace(/{IconJar}/i,           getLink(kLINKS.IconJar, 'IconJar'));
        text = text.replace(/{Round Icons}/i,       getLink(kLINKS.RoundIcons, 'Round Icons'));
        text = text.replace(/{Diversity Avatars}/i, getLink(kLINKS.DiversityAvatars, 'Diversity Avatars'));

        console.log('Post-Process text', text);

        return text;
    }
}

export default Utils;
