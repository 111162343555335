import React, {Component} from 'react';
// import Img from "gatsby-image"


class Callouts extends Component {

    static defaultProps = {
        duration : '1s'
    };

    render() {

        let headline;
        if (this.props.headline) {
            headline = <div className="row clearfix">
                <div className="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
                    <div className="heading heading-1 mb-80 text--center wow fadeInUp" data-wow-duration={this.props.duration}>
                        <h2 className="heading--title">{this.props.headline}</h2>
                        <p className="heading--desc"></p>
                        <div className="divider--line divider--center"></div>
                    </div>
                </div>
            </div>
        }

        return (
            <section id="feature1" className="section feature text-center bg-white">
                <div className="container">

                    {headline}

                    <div className="row">
                        {this.props.features.map((feature, i) => {
                            let icon;
                            if (feature.icon) {
                                icon = <div className="feature--icon">
                                    <i className="icon">
                                        <img src={feature.icon} alt={feature.iconAlt} />
                                    </i>
                                </div>
                            }

                            return (
                                <div key={`callout-${i}`} className="col-xs-12 col-sm-4 col-md-4">
                                    <div className="feature-panel wow fadeInUp" data-wow-duration="1s">
                                        {icon}
                                        <div className="feature--content">
                                            <h3>{feature.title}</h3>
                                            <p>{feature.text}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        )
    }
}

export default Callouts;
