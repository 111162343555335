/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {Component} from 'react';
import Divider from "../components/Divider"
import Feature from "../components/Feature"
import Callouts from "../components/Callouts"
import Footer from "../components/Footer"
import SignupForm from "../components/SignupForm"
import Meta from "../components/Meta"
import Header from "../components/Header"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
// import Utils from "../components/Utils"

import "../assets/css/external.css"
// import "bootstrap/dist/css/bootstrap.min.css"
import "../assets/css/bootstrap.min.css"
import "../assets/css/style.css"
import "../assets/css/custom.css"
import "../assets/css/form.css"

import callouts from '../data/callouts.json'
import features from '../data/features.json'

class HomePage extends Component {
    render() {
        return (
            <Layout>
                <div id={'wrapper'} className={'wrapper clearfix'}>
                    <Meta/>

                    <Header logo={'./logo/mason-logo-color.svg'} />

                    <Hero image={'./iconjar-for-ai/mason-palette.png'} typedText={`Open, Create, Edit, and Export IconJar files, directly in Illustrator`} />
                    <Divider/>

                    <Callouts features={callouts}/>
                    <Divider/>

                    {features.map((feature, i) => {
                        return (
                            <div key={`fragment-${i}`}>
                                <Feature
                                    key={`feature-${i}`}
                                    layout={feature.layout}
                                    image={feature.image}
                                    title={feature.title}
                                    text={feature.text}
                                    icon={feature.icon}
                                />
                                <Divider key={`divider-${i}`}/>
                            </div>
                        )
                    })}

                    <section id="newsletter2" className="section newsletter text-center">
                        <div className="container">
                            <div className="row clearfix">
                                <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                    <div className="heading heading-1 mb-60 text--center wow fadeInUp" data-wow-duration="1s">
                                        <h2 className="heading--title">Subscribe to get updates</h2>
                                        <p className="heading--desc">Enter your email address below to be notified when we launch. We promise never to sell, share, or otherwise misuse your email address or abuse your privacy or trust.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
                                    <div id="signup-form-bottom">
                                        <SignupForm/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Divider/>

                    <Footer name={'Atomic Lotus, LLC'}/>

                    <h2 id="free-trial-ribbon" className="ribbon">
                        <a href="https://aescripts.com/icon-mason">TRY IT FREE!</a>
                    </h2>
                </div>
            </Layout>
        )
    }
}

export default HomePage
