import React, {Component} from 'react';


class Footer extends Component {

    componentDidMount() {

        let _jquery = document.createElement("script");

        _jquery.id    = 'jquery';
        _jquery.src   = "./js/jquery-2.2.4.min.js";
        _jquery.async = true;

        document.body.appendChild(_jquery);

        _jquery.onload = () => {
            const _plugins = document.createElement("script");

            _plugins.id    = 'jquery';
            _plugins.src   = "./js/plugins.js";
            _plugins.async = true;

            document.body.appendChild(_plugins);

            _plugins.onload = () => {
                const _funcs = document.createElement("script");

                _funcs.id    = 'jquery';
                _funcs.src   = "./js/functions.js";
                _funcs.async = true;

                document.body.appendChild(_funcs);
            }

        }
    }

    render() {
        return (
            <footer id="footer" className="footer footer-5">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-4">
                            <div className="footer--copyright">
                                <span><a rel="me" href="https://vectoricons.net">vectoricons.net</a></span>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4">
                            <div className="footer--copyright">
                                <span><a rel="me" href="https://vectopus.com/designer/iconify">vectopus.com/designer/iconify</a></span>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-4">
                            <div className="footer--copyright">
                                <span>&copy;{new Date().getFullYear()} {this.props.name}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;
