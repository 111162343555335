/* eslint-disable */

import React, {Component} from 'react';

class SignupForm extends Component {

    componentDidMount() {
        if (! document.getElementById('converterKitScript')) {
            const script = document.createElement("script");

            script.id    = 'converterKitScript';
            script.src   = "https://f.convertkit.com/ckjs/ck.5.js";
            script.async = true;

            document.body.appendChild(script);
        }
    }

    render() {
       return(
        // https://f.convertkit.com/ckjs/ck.5.js
        <form
                action="https://app.convertkit.com/forms/1429612/subscriptions"
                className="mb-0 form-action seva-form formkit-form"
                method="post"
                data-sv-form="1429612"
                data-uid="7b0629ae43"
                data-format="inline"
                data-version="5"
                data-options='{"settings":{"after_subscribe":{"action":"message","success_message":"Success! Now check your email to confirm your subscription.","redirect_url":""},"analytics":{"google":null,"facebook":null,"segment":null,"pinterest":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":true,"url":"https://convertkit.com?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
                min-width="400 500 600 700 800"
            >
                <div data-style="clean">
                    <ul className="formkit-alert formkit-alert-error" data-element="errors" data-group="alert"></ul>
                    <div data-element="fields" data-stacked="false" className="seva-fields formkit-fields">
                        <div className="input-group formkit-field">
                            <input className="form-control xformkit-input" name="email_address" placeholder="Email Address"
                                   required="" type="email"/>
                            <span className="input-group-btn">
                                <button data-element="submit" className="btn btn--primary xformkit-submit">
                                    <div className="formkit-spinner">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <span>Subscribe</span>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </form>
       )
    }
}

export default SignupForm;
