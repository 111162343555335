import React, {Component} from 'react';
// import SignupForm from "../SignupForm";

import "../../assets/css/external.css"
import "../../assets/css/bootstrap.min.css"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
import "../../assets/css/form.css"

class Hero extends Component {
    render() {
        return (
            <section id="slider" className="section slider text-center">
                <div className="slide--item">
                    <div className="container">
                        <div className="row row-content mt-30">
                            <div className="col-xs-12 col-md-6">
                                <div className="col-xs-12 pt-50">
                                    <div className="slide--headline">
                                        {this.props.typedText}
                                    </div>
                                    <div className="slide--bio">
                                        Open, browse, and drop icons from your existing IconJars. Add, delete, or replace icons in existing
                                        IconJars. Or, export your artboards to a new IconJar right in Illustrator. What's more, you don't need
                                        to have IconJar installed (but we recommend it).
                                    </div>
                                </div>
                                <div className="col-xs-12 wow fadeInUp" data-wow-duration="1s">
                                    <div className="slide--action cta-buttons">
                                        <a href={'https://aescripts.com/icon-mason/'} className={'btn btn-primary btn-lg'}>Buy Now - $19.99</a>
                                        {/*<a href={'https://aescripts.com/icon-mason/'} className={'btn btn-primary btn-orange btn-lg'}>TRY IT</a>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="col-xs-12 col-sm-12 col-md-12 wow fadeInUp" data-wow-duration="1s">
                                    <div className="slide--holder mt-30">
                                        <img className="img-responsive center-block" src={this.props.image} alt="screens"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Hero
