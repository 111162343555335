import React, {Component} from 'react';
import Utils from "../../components/Utils"

class Feature extends Component {

    static defaultProps = {
        duration : '1s'
    };

    render() {
        if (this.props.layout === 'left') {
            return (
                <section id={this.props.id} className={'section feature feature-2'}>
                    <div className={'container'}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-7 wow fadeInUp">
                                <img className="img-responsive center-block" src={this.props.image} alt={this.props.imageAlt}/>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-5">
                                <div className="wow fadeInUp" data-wow-duration={this.props.duration}>
                                    <i className="icon"><img src={this.props.icon} alt={this.props.iconAlt}/></i>
                                    <div className="divider--line"></div>
                                    <h2 className="">{this.props.title}</h2>
                                    <p className="" dangerouslySetInnerHTML={{
                                        __html: Utils.processTokens(this.props.text)
                                    }}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        else {
            return (
                <section id={this.props.id} className={'section feature feature-2'}>
                    <div className={'container'}>
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 col-md-5">
                                <div className="wow fadeInUp" data-wow-duration={this.props.duration}>
                                    <i className="icon"><img src={this.props.icon} alt={this.props.iconAlt}/></i>
                                    <div className="divider--line"></div>
                                    <h2 className="">{this.props.title}</h2>
                                    <p className="" dangerouslySetInnerHTML={{
                                        __html: Utils.processTokens(this.props.text)
                                    }}></p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-7 wow fadeInUp">
                                <img className="img-responsive center-block" src={this.props.image} alt={this.props.imageAlt}/>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
    }
}

export default Feature;
